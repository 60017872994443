import data_icon from "./Images/new_data.png";
import edit_file from "./Images/edit_file.png"
import leaderboard_icon from "./Images/leaderboard.png";
import log_out_icon from "./Images/log_out.png";
import hamburger from "./Images/hamburger.png";
import profile_icon from "./Images/profile.png";
import bolt from "./Images/cart.png";
import bulb from "./Images/lightbulb.png";

function Sidebar({func, ondatasubmit, leaderload, shopload, accountload, serverTimeCheck, ondatamodify, tipsload }) {

    const logout = () =>{
        func(false)
    }

    if (serverTimeCheck) {
        return(
            <div className="sidebar">
                <div className="menu-top">
                    <img src={hamburger} alt="" />
                </div>
                <div className="menu-func-container">
                    <img src={data_icon} alt="" />
                    <button onClick={ondatasubmit}>Új mérés</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={bulb} alt="" />
                    <button onClick={tipsload}>Tippek & feladatok</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={leaderboard_icon} alt="" />
                    <button id="leaderboard" onClick={leaderload}>Ranglista</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={bolt} alt="" />
                    <button onClick={shopload}>Áruház</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={profile_icon} alt="" />
                    <button onClick={accountload}>Fiókom</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={log_out_icon} alt="" />
                    <button id="kijel" onClick={logout}>Kijelentkezés</button>
                </div>
                
            </div>
        );
    } else {
        return(
            <div className="sidebar">
                <div className="menu-top">
                    <img src={hamburger} alt="" />
                </div>
                <div className="menu-func-container">
                    <img src={edit_file} alt="" />
                    <button onClick={ondatamodify}>Mérés módosítása</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={leaderboard_icon} alt="" />
                    <button id="leaderboard" onClick={leaderload}>Ranglista</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={bolt} alt="" />
                    <button onClick={shopload}>Bolt</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={profile_icon} alt="" />
                    <button onClick={accountload}>Fiókom</button>
                </div>
                <br/>
                <div className="menu-func-container">
                    <img src={log_out_icon} alt="" />
                    <button id="kijel" onClick={logout}>Kijelentkezés</button>
                </div>
            </div>
        );
    }
}

export default Sidebar;