import React, { useState, useEffect } from "react";
import axios from "axios";
import back_icon from "../Images/back.png";
import "./Feladatok.css";

function Feladatok({ back }) {

}

export default Feladatok;

