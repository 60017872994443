import React, { useState } from 'react';
import Hatter from "./Images/hatter.png";
import axios from 'axios';
import Specific from './REgisterSpecific'

function Register({ backpressed, onRegisterSuccess, loading, onloading, offloading }) {
   
    const [values, setValues] = useState({
        nev: '',
        fnev: '',
        email: '',
        password: '',
    })

    const isValidUsername = (username) => {
      return !/\s/.test(username);
    };
    
    const isValidEmail = (email) => {
      return !/\s/.test(email);
    };

    const isValidPassword = (password) => {
      return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
    };

    const fieldNames = {
      nev: 'Teljes név',
      fnev: 'Felhasználónév',
      password: 'Jelszó',
      passw2: 'Jelszó megerősítése',
      email: 'E-mail cím',
    };
    
    const handleChange = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (const key in values) {
          if (!values[key]) {
            alert(`Hiba: A(z) ${fieldNames[key]} mezőt kötelező kitölteni.`);
            return;
          }
        }

        if (!isValidUsername(values.fnev) || !isValidEmail(values.email)) {
          alert(`Hiba: A(z) ${fieldNames.fnev} vagy ${fieldNames.email} nem tartalmazhat szóközt.`);
          return;
        }

        if (!isValidPassword(values.password)) {
          alert(`Hiba: A(z) ${fieldNames.password}nak legalább 8 karakternek kell lennie, tartalmaznia kell legalább egy nagybetűt és egy számot.`);
          return;
        }

        if (values.password !== values.passw2) {
          alert('Hiba: A jelszó és a jelszó megerősítése nem egyezik.');
          return;
        }

        onloading();
        axios.post('https://homecraftdb.crnl.hu/register', values)
            .then(res => {
                console.log("Registration response:", res.data);
                if (res.data.message === "Registration Successful") {
                  offloading();
                  onRegisterSuccess(values.fnev);
                } else if (res.data.message === "This email is already registered") {
                  offloading();
                  alert("Ez az email már foglalt");
                } else if (res.data.message === "This username is already registered") {
                  offloading();
                  alert("Ez a felhasználónév már foglalt");
                } else {
                    console.error("Registration failed:", res.data.error);
                    offloading();
                }
            })
            .catch(err => {
                console.error("Error during registration:", err);
                offloading();
            });
    }
    
    

    let reg = (
        <div id='container'>
          <div id='front' className="loginpanel">
            <h1 className="registerh1">Add meg az adataidat</h1>  
            <br/>
            <form id='registerform' onSubmit={handleSubmit}>
      
              <fieldset className="input-border">
                <legend>Teljes név</legend>
                <input type="text" id="nev" name="nev" placeholder="Teljes név" onChange={handleChange} className='inputbox' autoComplete='off' />
              </fieldset>
              <br />
      
              <fieldset className="input-border">
                <legend>Felhasználónév</legend>
                <input type="text" id="fnev" name='fnev' placeholder="Felhasználónév" onChange={handleChange} className='inputbox' autoComplete='off' />
              </fieldset>
              <br />
      
              <fieldset className="input-border">
                <legend>Jelszó</legend>
                <input type="password" id="passw1" name='password' placeholder="Jelszó" onChange={handleChange} className='inputbox' autoComplete='off' />
              </fieldset>
              <br />
      
              <fieldset className="input-border">
                <legend>Jelszó megerősítése</legend>
                <input type="password" id="passw2" name='passw2' placeholder="Jelszó megerősítése" onChange={handleChange} className='inputbox' autoComplete='off' />
              </fieldset>
              <br />
      
              <fieldset className="input-border">
                <legend>E-mail cím</legend>
                <input type="text" id="mail" name='email' placeholder="E-mail cím" onChange={handleChange} className='inputbox' autoComplete='off' />
              </fieldset>
              <br />
      
              <input type="submit" value="Új fiók létrehozása" id="submit" className='regsubmit' disabled={loading} />
            </form>
      
            <br id="br" />
            <button onClick={backpressed} className='register' id='register' disabled={loading}>Mégse</button>
          </div>
        </div>
    );
    return reg;
}

export default Register;
