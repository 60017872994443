import Coin from "../Images/coin.png";
import Offers from "./offers";

function Card({num, name, name_type, buyItem }) {
    let price;
    let cointrue = true;
    if (num === name_type ) {
        price = "✔";
        cointrue = false;
    } else if(num === 1){
        price = 4500;
    }else if(num === 2){
        price = 7500;
    }else if(num === 3){
        price = 5000;
    }else if(num === 4){
        price = 750;
    }else{
        price = "NaN"
    }
    
  
  let card;
  if(cointrue){
    card = (
        <div className="offer">
            <div className="offer-content">
                <Offers name={name} n={num}/>
            </div>
            <button className="price" onClick={() => buyItem(num, price)}>{price}<img src={Coin}/></button>
        </div>  
    );
  }else{
    card = (
        <div className="offer">
            <div className="offer-content">
                <Offers name={name} n={num}/>
            </div>
            <button className="price">{price}</button>
        </div>  
    );
  }
    return card;
}


export default Card;