import React, { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import axios from 'axios';
import Forgor_recover from "./Forgor_recover";
import "../OtherCode/landing.css";
import Hatter from "./Images/hatter.png";

export default function Forgor({closeForgor, loading, offloading, onloading}) {
    
    const emailRef = useRef();
    

    const [forgorRecovery, setForgorRecovery] = useState(false);

    const handleCloseForgorRecovery = () => {
        setForgorRecovery(false);
    }

    const onForgorRecovery = () => {
        setForgorRecovery(true);
    }

  

    
    useEffect(() => {
        emailjs.init("nGRPezMhWRCNx1M7Q");
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const serviceId = "service_b4dztbd";
        const templateId = "template_9gmy79k";
        const emailValue = emailRef.current.value;
          onloading();
          await axios.post('https://homecraftdb.crnl.hu/forgor', { email: emailValue })
          .then(res => {
            console.log("res data:", res.data)
            if (res.data === "Key generated successfully") {
                alert("Email elküldve a megadott címre");
                offloading();
            
            } else if (res.data === "Email does not exist") {
                alert("Az email címhez nem tartozik fiók");
                offloading();
            } else {
                  console.error("Registration failed:", res.data.error);
            }
          })
          .catch(err => {
              console.error("Error during registration:", err);
              alert("error during sending email");
              offloading();
          });
    };
    
    let output;

    if (forgorRecovery) { // forgor recover
        output = (
            <div className="App">
                <Forgor_recover closeForgorRecovery={handleCloseForgorRecovery} closeForgor={closeForgor} loading={loading} offloading={offloading} onloading={onloading} />
            </div>
        )
    }

    else {
        output = ( //alap, email bekérése
            <div id="container" style={{backgroundImage: `url(${Hatter})`}}>
                <div className="loginpanel">
                    <h1 className="registerh1">Jelszó helyreállítása</h1>
                    <form className="forgor-form" onSubmit={handleSubmit}>
                        <h3>Add meg email címed, hogy elküldhessük a helyreállításahoz szükséges kulcsot</h3>
                        <fieldset className="input-border">
                            <legend>Add meg az email címed</legend>
                            <input ref={emailRef} type="email" placeholder="Add meg az email címed" className="inputbox"/>
                        </fieldset>
                        <br/>
                        <button id="submit" disabled={loading} style={{marginBottom: `-15%`, width: `70%`}} > email küldése </button>
                        <br/>
                        <br/>
                        <br/>
                        <button id="submit" disabled={loading} onClick={onForgorRecovery} style={{width: `70%`}} > Jelszó helyreállítása </button>
                    </form>
                    <button className="register" style={{marginTop: `-10%`}} disabled={loading} onClick={closeForgor}> vissza </button>
                </div>
            </div>
        )
    }
    return output;
    
};