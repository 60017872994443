import React from 'react';
import axios from 'axios';
import back_icon from "./Images/back.png";
import '../OtherCode/landing.css';

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aram: 0.0,
            gaz: 0.0,
            ido: new Date().toISOString(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { userId, datasubmitted, setServerTimeCheck } = this.props;
        const { aram, gaz, ido, } = this.state;

        axios.post('https://homecraftdb.crnl.hu/data', {
            userId,
            aram,
            gaz,
            ido,
        })
        .then(response => {
            if (response.data.message === "Data Uploaded Successfully") {
                console.log(response);
                alert("Adatok sikeresen rögzítve!");
                datasubmitted();
                setServerTimeCheck(false);
                window.location.reload(false);
            } else {
                alert("A megadott adatok nem lehetnek kisebbek az előző adatoknál!");
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div id='container' className='data-container'>
                <button onClick={this.props.datasubmitted} className='back'><img src={back_icon} alt="" /></button>
                <div className='loginpanel'>
                    <form onSubmit={this.handleSubmit} className='data-form'>
                        <h1 className='data-title'>Óraállások rögzítése</h1>
                        <fieldset>
                            <legend>Villanyóra állásod</legend>
                            <input className='inputbox' placeholder="Villanyóra állás" type="text" name="aram" onChange={this.handleChange} autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <fieldset>
                            <legend>Gázóra állásod</legend>
                            <input className='inputbox' placeholder='Gázóra állás' type="text" name="gaz" onChange={this.handleChange} autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <input id='submit' type="submit" value="Óraállások feltöltése" />
                    </form>
                </div>
            </div>
        );
    }
}
export default Data;