import './App.css';
import Landing from './Components/Landing';
import Register from './Components/Register';
import { useState } from 'react';
import Main from './Components/Main.js';
import axios from 'axios';
import Specific from './Components/REgisterSpecific.js';
import Hatter from './Components/Images/hatter.png';
import React, { useEffect } from 'react';
import './OtherCode/landing.css';
import Forgor from './Components/Forgor.js';
import Cookies from 'js-cookie';
import Terms from './Components/Terms.js';

function App() {
  const [canlogin, setCanlogin] = useState(false);
  
  const savedPassword = Cookies.get('password');
  const initPassword = savedPassword ? JSON.parse(savedPassword) : null;
  const [password, setPassword] = useState(initPassword);
  
  const savedUid = Cookies.get('userId');
  const initUid = savedUid ? JSON.parse(savedUid) : null;
  const [userId, setUserId] = useState(initUid);
  
  const savedLoginusername = Cookies.get('loginusername');
  const initLoginusername = savedLoginusername ? JSON.parse(savedLoginusername) : null;
  const [loginusername, setLoginusername] = useState(initLoginusername);

  const [autologgingin, setAutologgingin] = useState(false);

  useEffect(() => {
    if (!canlogin && loginusername && password) {
      autoLogin(loginusername, password);
      setAutologgingin(true);
    }
  }, [canlogin, loginusername, password]);
  
  useEffect(() => {
    Cookies.set('canlogin', JSON.stringify(canlogin), { expires: 7 });
  }, [canlogin]);

  useEffect(() => {
    Cookies.set('userId', JSON.stringify(userId), { expires: 7 });
  }, [userId]);

  useEffect(() => {
    Cookies.set('loginusername', JSON.stringify(loginusername), { expires: 7 });
  }, [loginusername]);

  useEffect(() => {
    Cookies.set('password', JSON.stringify(password), { expires: 7 });
  }, [password]);
  
  
  
  const [registeredUsername, setRegisteredUsername] = useState(null);
  const [register, setRegister] = useState(false);
  
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [score, setScore] = useState(null);
  const [forgor, setForgor] = useState(false);
  const [terms, setTerms] = useState(false);

  const [name_type, setName_type] = useState(null);


  
  const handleOffLoading = () => {
    setLoading(false);
  }

  const handleOnLoading = () => {
    setLoading(true);
  }

  const handleRegisterSuccess = async (username) => {
    setRegistrationSuccess(true);
    setRegister(false);
    setCanlogin(false);
    await setRegisteredUsername(username);
}


  const handleRegister = () => {
    setRegister(true);
    setRegistrationSuccess(false);
  }

  const handleForgor = () => {
    setForgor(true);
  }

  const handleBack = () => {
    setRegister(false);
    setCanlogin(false);
    setRegistrationSuccess(false);
  }

  const handleCloseForgor = () => {
    setForgor(false);
  }


  const allowLogin = async (username, password) => {
    try {
      const response = await axios.post('https://homecraftdb.crnl.hu/login', {
        fnev: username,
        password: password,
      });
  
      if (response.data.message === "Login Successfully") {
        setCanlogin(true);
        setUserId(response.data.id);
        setScore(response.data.score);
        setName_type(response.data.name_type);
        setLoginusername(username);
        setPassword(response.data.hashedpass);
      } else {
        alert('Wrong Username or Password!');
      }
    } catch (error) {
      console.error('Error during login:', error.message);
      alert('Error during login. Please try again.');
    }
  };


  const autoLogin = async (loginusername, password) => {
    try {
      const response = await axios.post('https://homecraftdb.crnl.hu/autologin', {
        fnev: loginusername,
        password: password,
      });
      if (response.data.message === "Login Successfully") {
        setCanlogin(true);
        setAutologgingin(false);
        setUserId(response.data.id);
        setScore(response.data.score);
        setName_type(response.data.name_type);
        setLoginusername(loginusername);
      } else {
        setCanlogin(false);
        setLoginusername(null);
        setPassword(null);
      }
    } catch (error) {
      console.error('Error during login:', error.message);
      alert('Automatikus bejelentkezés sikertelen!');
      setCanlogin(false);
    }
  };

  const loadterms = () => {
    setTerms(true)
  }


  const handleLogout = () => {
    setCanlogin(false);
    setLoginusername(null);
    setPassword(null);
  }

  let output;
  
  if (autologgingin) {
    output = (
      <div className='App'>
        <h1>Betöltés...</h1>
      </div>
    );
  } else if (registrationSuccess && !canlogin) {
    output = (
        <div className='App' >
          <Specific letLogin={setCanlogin} registeredUsername={registeredUsername} loading={loading} offloading={handleOffLoading} onloading={handleOnLoading} />
        </div>
    );
  } else if (canlogin) {
    output = (
      <div className='App'>
        <Main changelogin={handleLogout} userId={userId} loginusername={loginusername} score={score} name_type={name_type} />
      </div>
    ); 
  } else if (forgor) {
    output = (
      <div className='App'>
        <Forgor closeForgor={handleCloseForgor} onloading={handleOnLoading} offloading={handleOffLoading} loading={loading}/>
      </div>
    ) 
  } else if (register && !canlogin) {
    output = (
      <div className='App' style={{backgroundImage: `url(${Hatter})`}}>
        <Register backpressed={handleBack} onRegisterSuccess={handleRegisterSuccess} loading={loading} offloading={handleOffLoading} onloading={handleOnLoading} />
      </div>
    );
  }else if(terms){ 
    output = (
      <Terms/>
    );
  }else {
    output = (
      <div className='App'>
        <Landing submit={allowLogin} onregister={handleRegister} onforgor={handleForgor} loading={loading} terms={loadterms}/>
      </div>
    );
  }
  return output;
}

export default App;
