import React, { useState, useEffect } from "react";
import "./profile.css";

function Selectname({ name }) {
    const [colors, setColors] = useState({
        startColor: "#ff0000",
        endColor: "#0000ff"
    });

    const [textStyle, setTextStyle] = useState({
        background: `linear-gradient(90deg, ${colors.startColor} 0%, ${colors.endColor} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        placeSelf: 'center'
    });

    const changestart = (e) => {
        const newStartColor = e.target.value;
        setColors({ ...colors, startColor: newStartColor });
        setTextStyle({
            ...textStyle,
            background: `linear-gradient(90deg, ${newStartColor} 0%, ${colors.endColor} 100%)`
        });
    };

    const changeend = (e) => {
        const newEndColor = e.target.value;
        setColors({ ...colors, endColor: newEndColor });
        setTextStyle({
            ...textStyle,
            background: `linear-gradient(90deg, ${colors.startColor} 0%, ${newEndColor} 100%)`
        });
    };


    let option;
    let options = [];
    for(let i = 0; i < 10; i++){
        if (i === 0) {
            option = (
                <div className="nameoption">
                    <h1>{name}</h1>
                    <input type="submit" value="Használat"/>
                </div>
            );
            options.push(option);
        }else if (i === 1) {
            option = (
                <div className="nameoption">
                    <h1 style={textStyle}>{name}</h1>
                    <div className="select-color">
                        <label>Kezdőszín: </label>
                        <input type="color" value={colors.startColor} onChange={changestart} />
                        <br />
                        <label>Végszín: </label>
                        <input type="color" value={colors.endColor} onChange={changeend} />
                    </div>
                    <input type="submit" value="Használat" />
                </div>
            );
            options.push(option);
        }else if (i === 2) {
            option = (
                <div className="nameoption">
                    <svg>
                        <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                            {name}
                        </text>
                    </svg>
                    <input type="submit" value="Használat"/>
                </div>
            );
            options.push(option);
        }
    }


    return options;
}

export default Selectname;
