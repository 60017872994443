import Hatter from "./Images/hatter.png";
import "../OtherCode/landing.css";
import { useState } from "react";

function Landing({submit, onregister, onforgor, loading, terms }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(username, password);
  }



  return (
    <div id="container" style={{backgroundImage: `url(${Hatter})`}}>
        <div id="loginpanel">
            <h1>Üdvözlünk!</h1>
            <div id="form">
              <form onSubmit={handleSubmit}>
                <fieldset className="input-border">
                  <legend>Felhasználónév</legend>
                  <input type="text" id="fnev" placeholder="Felhasználónév" onChange={handleUsernameChange} value={username} className="inputbox format" autoComplete="off"/>
                </fieldset>
                  <br/>
                <fieldset>
                  <legend>Jelszó</legend>
                  <input type="password" id="passw" placeholder="Jelszó" onChange={handlePasswordChange} value={password} className="inputbox format" autoComplete="off"/>
                </fieldset>
                  <br />
                <input type="submit" id="submit" value="Bejelentkezés"/>
              </form>
            </div>
            <button id="forgor" className="register" disabled={loading} onClick={onforgor}>Elfelejtettem a jelszavamat</button>
            <button id='registerbutton' className="register "  disabled={loading} onClick={onregister} >Fiók létrehozása</button>
            <button className="register terms" disabled={loading} onClick={() => window.open('https://homecraft.crnl.hu/terms-and-conditions')}>Felhasználási feltételek és adatkezelési tájékoztató</button>
        </div>
    </div>
  );
}

export default Landing;