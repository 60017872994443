import "../Shop/offers.css";


function Row({ place, name, score, name_type }) {
    let color;

    console.log("name type: ", name_type);

    if (place === 1) {
        color = "#FFD700";
    } else if(place === 2) {
        color = "#C0C0C0"
    } else if(place === 3){
        color ="#CD7F32"
    } else{
        color = 'white'
    }

    let row;
    
    if (name_type === 0) {
        row = (
        <div className="row" >
            <div className="place"><h2 style={{ color: color }}>{place}</h2></div>
            <div className="name"><h2 style={{ color: color }}>{name}</h2></div>
            <div className="score"><h2 style={{ color: color }}>{score}</h2></div>
        </div>
        );
    } else if (name_type === 1) {
        row = (
        <div className="row" >
            <div className="place"><h2 style={{ color: color }}>{place}</h2></div>
            <div className="name">
                <h2 className="gradient">{name}</h2>
            </div>
            <div className="score"><h2>{score}</h2></div>
        </div>
        );
    } else {
        row = (
        <div className="row" >
            <div className="place"><h2 style={{ color: color }}>{place}</h2></div>
                <div className="name">
                    <svg>
                        <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                            {name}
                        </text>
                    </svg>
                </div>
            <div className="score"><h2>{score}</h2></div>
        </div>
        );
    }
    
    return row;
}

export default Row;
