import React, { useState } from 'react';
import axios from "axios";
import back_icon from "../Images/back.png";
import Coin from "../Images/coin.png";
import Nameplate from "../Nameplate.js";
import Card from "./Card.js";
import "./shop.css";

function Shop({ back, name, money, name_type, uid }) {
    let cards = [];
    let offers = 20;
    let card;
    const [shopmoney, setShopmoney] = useState(money);
    const [shopnametype, setShopnametype] = useState(name_type);


    const buyItem = (num, price) => {
        if (price > shopmoney) {
            alert("Purchase failed: Insufficient funds!");
        } else {
            axios.post("https://homecraftdb.crnl.hu/purchase", {
                num,
                uid,
                price
            })
            .then(response => {
                if (response.data.message === "Transaction successful!") {
                    setShopmoney(shopmoney - price);
                    setShopnametype(num);
                    alert("Purchase successful!");
                } else {
                    alert("Purchase failed!");
                    console.error("Purchase failed:", response.data.error)
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    let br = <br />;
    let everything = [];
    for (let i = 0; i < offers; i++) {
        card = <Card num={i + 1} key={i} name={name} name_type={shopnametype} buyItem={buyItem}/>;
        cards.push(card);
        if (cards.length === 2) {
            let pair = (
                <div className="card" key={i}>
                    {cards}
                </div>
            );
            everything.push(pair);
            everything.push(br);
            cards = [];
        }
    }


    

    return (
        <div id="shop">
            <div className="title">
                <button onClick={() => window.location.reload(false)} className="back">
                    <img src={back_icon} alt="" />
                </button>
                <header>
                    <h1>Áruház</h1>
                    <Nameplate loginusername={name} score={shopmoney} name_type={shopnametype} />
                </header>
                
            </div>
            <div className="offer-container">{everything}</div>
        </div>
    );
}

export default Shop;
