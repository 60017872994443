import Level from "./Level";
import Logo from "./Images/logo192.png";
import "../OtherCode/main.css";
import { useState } from "react";
import "./Shop/offers.css";
import "./Shop/shop.css";

function Nameplate({ loginusername, score, name_type }) {
  let nameplate;
  
  if (name_type === 0) {
    nameplate = (
      <div className="nameplate">
        <div className="nameplate-info">
          <div className="border">
            <img src={Logo} alt="Logo" className="pp" />
          </div>
          <p className="name" translate="no">{loginusername}</p>
        </div>
        <div className="level-holder">
          < Level score={score} />
        </div>
      </div>
    );
  } else if (name_type === 1) {
    nameplate = (
      <div className="nameplate">
        <div className="nameplate-info">
          <div className="border">
            <img src={Logo} alt="Logo" className="pp" />
          </div>
          <div className="product">
                <h2>{loginusername}</h2>
          </div>
        </div>
        <div className="level-holder">
          < Level score={score} />
        </div>
      </div>
    );
  } else {
    nameplate = (
      <div className="nameplate">
        <div className="nameplate-info">
          <div className="border">
            <img src={Logo} alt="Logo" className="pp" />
          </div>
          <div className="product_svg">
            <svg>
                <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                    {loginusername}
                </text>
            </svg>
          </div>
        </div>
        <div className="level-holder">
          < Level score={score} />
        </div>
      </div>
    );
  }
  
  return nameplate
}

export default Nameplate;