import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../OtherCode/landing.css";
import Hatter from "./Images/hatter.png";


function Specific({ letLogin, registeredUsername, loading, offloading, onloading }) {

    const [spec, setSpec] = useState({
        terulet : '',
        futes : 'aram',
        lakok : '',
        szigeteles : false,
    });

    useEffect(() => {
        // Itt küldje el a kérést, amikor a spec változik
        console.log("Effect triggered with spec:", spec);
    }, [spec]);

    

    
    const specificChange = (event) => {
        const { name, value, type, checked } = event.target;
        setSpec({
            ...spec,
            [name]: type === 'checkbox' ? checked : value
        });
    }

    
    const sendRequest = () => {
        if (registeredUsername) {
            onloading();
            axios.post('https://homecraftdb.crnl.hu/specific', { ...spec, fnev: registeredUsername })
                .then(res => {
                    console.log("Registration response:", res.data);
                    if (res.data.message === "Specific Details Updated Successfully") {
                        alert("Sikeres regisztráció!");
                        offloading();
                        letLogin();
                        window.location.reload();
                    } else {
                        console.error("Specific details update failed:", res.data.error);
                    }
                })
                .catch(err => {
                    console.error("Error during specific details update:", err);
                });
        }
    }
    
    const specified = (event) => {
        event.preventDefault();
        console.log("Sending request with payload:", { ...spec, fnev: registeredUsername });
        sendRequest();
    }


    return(
        <div id='container' style={{backgroundImage: `url(${Hatter})`}}>
            <div className="loginpanel">
                <h1 id='specifich1'>Add meg háztartásod lényeges adatait</h1>
                <div id='specific'>
                    <form onSubmit={specified}>
                        <fieldset className='input-border'>
                            <legend>Lakásod alapterülete m²-ben</legend>
                            <input type="text" id="terulet" name="terulet" placeholder="Lakásod alapterülete m²-ben" onChange={specificChange} className="inputbox" autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <fieldset>
                            <legend>Háztartásodban élők száma</legend>
                            <input type="text" id="lakok" name="lakok" placeholder="Háztartásodban élők száma" onChange={specificChange} className="inputbox" autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        
                        <h3 className="text" >Fűtés típusa:</h3>
                        <select className="dropdown" name="futes" id="futes" onChange={specificChange}>
                            <option value="aram"> Áram</option>
                            <option value="gaz">Gáz/Olaj</option>
                            <option value="fatuzeles">Fatüzelés</option>
                            <option value="tavho">Távhő</option>
                            <option value="egyeb">Egyéb</option>
                        </select>
                        <br/>
                        <label className='boxlabel'>Házam szigetelve van</label>
                        <input 
                            type='checkbox' 
                            name='szigeteles' 
                            className='checkbox' 
                            checked={spec.szigeteles} 
                            onChange={specificChange}
                        />
                        <br />
                        <br />
                        <br />
                        <input type="submit" id="submit" disabled={loading} value="Regisztrálok"/>
                    </form>
                </div>
            </div>
        </div>
    );
}


export default Specific;