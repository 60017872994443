import React, { useState } from 'react';
import Data from "./Data";
import Nameplate from "./Nameplate";
import Sidebar from "./Sidebar";
import Dataquery from './Dataquery';
import BarChart from "./Chart";
import Leaderboard from './Leaderboard/Leaderboard';
import Shop from './Shop/shop';
import Profile from './Profile page/Profilepage';
import ModifyData from './MofifyData';
import "../OtherCode/main.css";
import "../OtherCode/ToggleSwitch.css";
import Tips from './Tips/Tips';
import Feladatok from './Feladatok/Feladatok';

function Main({ changelogin, userId, loginusername, score, name_type }) {
  const [datasubmit, setdatasubmit] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [loadLeaderboard, setLoadLeaderboard] = useState(false);
  const [loadShop, setloadShop] = useState(false);
  const [loadProfile, setloadProfile] = useState(false);
  const [loadTips, setloadTips] = useState(false);
  const [serverTimeCheck, setServerTimeCheck] = useState(false);
  const [modifyData, setModifyData] = useState(false);
  const [lastData, setLastData] = useState(null);
  const [toggle, setToggle] = useState("aram");
  const [loadFeladatok, setLoadFeladatok] = useState(false);

  const toggleValue = () => {
    setToggle(toggle === 'aram' ? 'gaz' : 'aram');
  };

  const updateChartData = (data) => {
    setChartData(data);
  };


  const data = chartData.aram;
  const labels = chartData.ido;

  console.log('server time check is: ', serverTimeCheck);

  let main;
  
  if(datasubmit){
    main = (
      <Data userId={userId} datasubmitted={() => setdatasubmit(false)} setServerTimeCheck={setServerTimeCheck} updateChartData={updateChartData} />
    );
  } else if (modifyData) {
    main = (
      <ModifyData offdatamodify={() => setModifyData(false)} lastdata={lastData} userId={userId}/>
    );
  }else if(loadLeaderboard){
    main = (
      <div id='main' >
        <div id='home'>
          <Leaderboard username={loginusername} back={() => setLoadLeaderboard(false)}/>
        </div>
      </div>
    );
  }else if(loadShop){
    main = (
      <div id='main'>
        <div id='home'>
          <Shop back={() => setloadShop(false)} name={loginusername} money={score} name_type={name_type} uid={userId} />
        </div>
      </div>
    );
  }else if(loadProfile){
    main = (
      <div id='main'>
        <div id='home'>
          <Profile name={loginusername} back={() => setloadProfile(false)} nametype={name_type}/>
        </div>
      </div>
    );
  }else if(loadTips){
    main = (
      <div id='main'>
        <div id='home'>
          <Tips back={() => setloadTips(false)} tippek={() => setLoadFeladatok(true)} />
        </div>
      </div>
    );
  }else if(loadFeladatok){
    main = (
      <div id='main'>
        <div id='home'>
          <Feladatok/>
        </div>
      </div>
    )
  }else{
    main = (
      <div id='main'>
        <Dataquery userId={userId} updateChartData={updateChartData} serverTimeCheck={setServerTimeCheck} modifylastdata={setLastData} />
        <div id='home' className="main-container">
        
          <div id='header'>
            <Nameplate loginusername={loginusername} score={score} name_type={name_type} />
          </div>

          <div className='toggle-switch-container'>
            <div className='toggle-switch'>
              <span className={`option ${toggle === 'aram' && 'active'}`} onClick={() => setToggle('aram')}>Áram</span>
              
              <span className={`option ${toggle === 'gaz' && 'active'}`} onClick={() => setToggle('gaz')}>Gáz</span>
            </div>
          </div>
          
          <div className="content-container">
           
            <div className="sidebar-container">
              <Sidebar func={changelogin} ondatasubmit={setdatasubmit} ondatamodify={setModifyData} leaderload={setLoadLeaderboard} shopload={setloadShop} accountload={setloadProfile} serverTimeCheck={serverTimeCheck} tipsload = {() => setloadTips(true)}/>
            </div>
            
            <div className="chart-container">
            
              <BarChart data={chartData} labels={labels} toggle={toggle} />
              
            </div>
            
          </div>
          
        </div>
        
      </div>
      
    );
  }

  return main;

}

export default Main;