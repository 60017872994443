import React, { useState, useEffect } from 'react';
import Row from './Row';
import axios from 'axios';
import back_icon from "../Images/back.png";

function Leaderboard({ username, back }) {
    const [tipus, setTipus] = useState(null);
    const [elementarray, setElementarray] = useState(null);

    useEffect(() => {
        axios.post('https://homecraftdb.crnl.hu/scorequery')
            .then(res => {
                console.log("API response:", res);  // Log the entire response
                if (res.data.error) {
                    console.error("Error during getting leaderboard", res.data.error);
                } else if (res.data === "User not found") {
                    console.error("User not found");
                } else {
                    setTipus("Gázhasználat");
                    const users = res.data.data;
                    const names = res.data.user;  // Access the entire array of users

                    if (users && users.length > 0 && names && names.length > 0) {
                        const elements = users.map((user, index) => (
                            <Row  place={index + 1} name={user.username} score={user.boardscore} name_type={names[index].name_type}/>
                        ));

                        setElementarray(elements);
                    } else {
                        console.error("No users found in the data");
                    }
                }
            })
            .catch(error => {
                console.error("Error during axios.post", error);
            });
    }, []); // Empty dependency array ensures that useEffect runs only once on component mount

    // Conditional rendering when data is not available
    if (!elementarray) {
        return <div>Loading...</div>;
    }

    return (
        <div className="table-container">
            <button className="back" onClick={back}><img src={back_icon} alt="" /></button>
            <h1 className='title-background '>{tipus} legjobbjai</h1>
            <div className='table'>
                {elementarray}
            </div>
        </div>
    );
}

export default Leaderboard;
