import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import Hatter from "./Images/hatter.png";
import "../OtherCode/landing.css";

export default function Forgor_recover({closeForgorRecovery, closeForgor, loading, offloading, onloading }) {
    
    const recoverycodeRef = useRef();
    
    const [codecorrect, setCodecorrect] = useState(false);

    const newpasswordRef = useRef();

    const newPasswordConfirmRef = useRef();

    const [getbackemail, setGetbackemail] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const recoverycodeValue = recoverycodeRef.current.value;
            onloading();
            await axios.post('https://homecraftdb.crnl.hu/forgor_recovery_check', {code: recoverycodeValue})
            .then(res => {
                if (res.data.error) {
                    console.error("Checking recovery code failed:", res.data.error);
                    offloading();
                } else if (res.data === "Recovery code is not correct") {
                    alert("Helytelen helyreállítási kulcs");
                    offloading();
                } else {
                    setGetbackemail(res.data[0].email);
                    const emailValue = res.data[0].email;
                    offloading();
                    setCodecorrect(true);
                    alert("A megadott kulcs helyes: " + emailValue);
                    console.log("getbackemail: ", getbackemail);
                    console.log("res data email: ", emailValue);
                    console.log("res data: ", res.data);
                }
            })
            .catch(err => {
                console.error("Error during checking recovery code:", err);
                alert("Hiba a kulcs ellenörzésekor");
                offloading();
            });

    };



    const handleSubmitnew = async (i) => {
        i.preventDefault();
        const newpasswordValue = newpasswordRef.current.value;
        const newPasswordConfirmVAlue = newPasswordConfirmRef.current.value;
        if (newpasswordValue === newPasswordConfirmVAlue) {
            onloading();
            await axios.post('https://homecraftdb.crnl.hu/forgor_recovery_set', {password: newpasswordValue, email: getbackemail})
            .then(res => {
                if (res.data === "New password set successfully") {
                    alert("Új jelszó sikeresen beállítva");
                    offloading();
                    closeForgor();
                } else {
                    console.error("Setting new passowrd failed:", res.data.error);
                    offloading();
                }
            })
            .catch(err => {
                console.error("Error during setting new password:", err);
                alert("Error during setting new password");
                offloading();
            });
        } else {
            alert("A jelszavak nem egyeznek!");
        }

    };

    let output;

    if (codecorrect) { // új jelszó
        output = (
        <div id="container" style={{backgroundImage: `url(${Hatter})`}}>
            <div className="loginpanel">
                
                <form onSubmit={handleSubmitnew}>
                    <h1 id="specifich1">Állítsd be új jelszavad</h1>
                    <fieldset >
                        <legend >Új jelszó</legend>
                        <input ref={newpasswordRef} type="password" placeholder="Új jelszó" className="inputbox"/>
                    </fieldset>
                    <br/>
                    <fieldset >
                        <legend >Új jelszó megerősítése</legend>
                        <input ref={newPasswordConfirmRef} type="password" placeholder="Új jelszó megerősítése" className="inputbox" />
                    </fieldset>
                    <br/>
                    <button id="submit" disabled={loading}> Új jelszó beállítása </button>
                </form>
                <button className="register" disabled={loading} onClick={closeForgorRecovery}> vissza </button>
            </div> 
        </div>
        )
    } else { //recovery kód kérése
        output = (
        <div id="container" style={{backgroundImage: `url(${Hatter})`}}>
            <div id="loginpanel">
                <form onSubmit={handleSubmit}>
                    <h2>Add meg a helyreállítási kulcsot</h2>
                    <fieldset className="input-border">
                        <legend>Recovery kulcs</legend>
                        <input ref={recoverycodeRef} type="text" placeholder="Helyreállítási kulcs" className="inputbox"/>
                    </fieldset>
                    <br/>
                    <button id="submit" disabled={loading}> Jelszó Visszaállítása </button>
                </form>
                <button className="register" disabled={loading} onClick={closeForgorRecovery}> Vissza </button>
            </div>
        </div>
        )
    }

    return output;
};