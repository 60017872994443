import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import '../OtherCode/main.css';

function BarChart({ data, toggle }) {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const formattedLabels = data.map((item) => {
    const date = new Date(item.ido);
    return date.toISOString().split('T')[0]; // Extracting the date part
  });

  const aramArray = data.map((item) => item.aram);
  const gazArray = data.map((item) => item.gaz);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const consumption = aramArray.map((value, index) => {
      return index === 0 ? null : value - aramArray[index - 1];
    });

    const gasConsumption = gazArray.map((value, index) => {
      return index === 0 ? null : value - gazArray[index - 1];
    });


    const labels = formattedLabels.slice(1);

    if (toggle === 'aram'){
      chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Heti fogyasztás(kWh)',
                data: consumption.slice(1),
                backgroundColor: 'grey', // pontok
                borderColor: 'black', // vonal
                borderWidth: 2, // vastagság
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'category',
                labels: labels,
                legend: "black",
                ticks:{
                  color: "black",
                },
                grid: {
                  color: 'rgb(70, 97, 77)', // oldalsó oszlopok
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  color: "black",
                },
                grid: {
                  color: 'rgb(70, 97, 77)', // vízszintes vonalak
                },
              },
            },
            plugins: {
              legend: {
                display: false, // Hiding the legend
              },
            },
          },
      });
    }else if(toggle === 'gaz'){
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Heti fogyasztás (gáz)',
              data: gasConsumption.slice(1),
              backgroundColor: 'grey', // pontok
              borderColor: 'black', // vonal
              borderWidth: 2, // vastagság
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'category',
              labels: labels,
              legend: "black",
              ticks:{
                color: "black",
              },
              grid: {
                color: 'rgb(70, 97, 77)', // oldalsó oszlopok
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: "black",
              },
              grid: {
                color: 'rgb(70, 97, 77)', // vízszintes vonalak
              },
            },
          },
          plugins: {
            legend: {
              display: false, // Hiding the legend
            },
          },
        },
    });
    }

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }, [aramArray, formattedLabels]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      const containerWidth = chartContainer.clientWidth;
      const containerHeight = chartContainer.clientHeight;

      chartRef.current.width = containerWidth;
      chartRef.current.height = containerHeight;

      if (chartInstance.current) {
        chartInstance.current.resize();
        chartInstance.current.update();
      }
    });

    resizeObserver.observe(chartContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className='chart-container' ref={chartContainerRef}>
      <div className='chart'>
        <canvas ref={chartRef} style={{backgroundColor: "rgba(182, 243, 213, 0.75)"}}></canvas>
      </div>
    </div>
  );
}

export default BarChart;
