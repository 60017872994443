import './offers.css';
import bgforsale from "../Images/background-for-sale.webp";
import Coin from "../Images/coin.png";

function Offers({name, n}){


    let offer;
    if(n===1){
        offer = (
            <div className="product">
                <h2>{name}</h2>
            </div>
        );
    }else if(n===2){
        offer = (
            <div className="product">
                <svg>
                    <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                        {name}
                    </text>
                </svg>
            </div>
        );
    }else if(n === 3){
        offer = (
            <div className='product'>
                <img src={bgforsale} className='hex'/>
            </div>
        );
    }else if(n===4){
        offer = (
            <div className="product">
                <div className="discount">
                    <h1> 7 nap 50% extra</h1> 
                    <img src={Coin}/>
                </div>
            </div>
        );
    }else{
        offer = (
            <h1>Még nem elérhető!</h1>
        );
    }

    return offer;
}

export default Offers;