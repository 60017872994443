import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Dataquery = ({ userId, updateChartData, serverTimeCheck, modifylastdata }) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://homecraftdb.crnl.hu/dataquery', { userId });
        console.log('Data query response:', response.data.data);
        console.log('Server time: ', response.data.servertime);

        const newRecords = response.data.data;
        setRecords(newRecords);
        updateChartData(newRecords);
        modifylastdata(newRecords[newRecords.length - 1]);

        const lastRecordDate = newRecords.length > 0 ? new Date(newRecords[newRecords.length - 1].ido) : null;
        const serverTime = new Date(response.data.servertime);
        console.log('servertime: ', serverTime);
        console.log('lastrecordtime: ', lastRecordDate);

        const serverDate = serverTime.toISOString().split('T')[0];
        const lastRecordDateStr = lastRecordDate ? lastRecordDate.toISOString().split('T')[0] : null;

        if (serverDate > lastRecordDateStr && lastRecordDate !== "") {
          serverTimeCheck(true);
          console.log('server time check set to true by dataquery');
        } else if (lastRecordDate === null) {
          serverTimeCheck(true);
          console.log('server time check set to true by dataquery');
        } else {
          setLastRecordId(newRecords[newRecords.length - 1]?.id);
          serverTimeCheck(false);
          console.log('server time check set to false by dataquery');
        }
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchData();
  }, [userId]);

  console.log('Records:', records);
/*  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ maxWidth: '600px' }}>
        <h1>Rögzített adatok:</h1>
        <ul>
          {records.map((record, index) => {
            const date = new Date(record.ido);
            const formattedTime = date.toISOString().split('T')[0];
            return (
              <li key={`${record.id}-${index}`}>
                Idő: {formattedTime}, Áram: {record.aram}, Gáz: {record.gaz}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
*/ 
  
  
};

export default Dataquery;
