import React from 'react';
import axios from 'axios';
import '../OtherCode/landing.css';
import back_icon from "./Images/back.png";

class Data extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.lastdata.id,
            uid: this.props.userId,
            aram: this.props.lastdata.aram,
            gaz: this.props.lastdata.gaz,
            ido: new Date().toISOString(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { offdatamodify, lastdata } = this.props;
        const { uid, aram, gaz, ido, id, } = this.state;

        axios.post('https://homecraftdb.crnl.hu/modifydata', {
            id,
            uid,
            aram,
            gaz,
            ido,
        })
        .then(response => {
            if (response.data.message === "Data updated successfully") {
                console.log(response);
                alert("Adatok sikeresen módosítva!");
                window.location.reload(false);
                offdatamodify();
                setServerTimeCheck(false);
            } else {
                alert("A megadott adatok nem lehetnek kisebbek az előző adatoknál!");
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div id='container' className='data-container'>
                 <button onClick={this.props.offdatamodify} className="back"><img src={back_icon}/></button>
                <div className='loginpanel'>
                    <form onSubmit={this.handleSubmit}>
                        <h1>Add meg óraállásaidat</h1>
                        <fieldset>
                            <legend>Villanyóra állásod</legend>
                            <input className='inputbox' placeholder="Vilanyóra állásod" type="text" name="aram" onChange={this.handleChange} defaultValue={this.props.lastdata.aram} autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <fieldset>
                            <legend>Gázóra állásod</legend>
                            <input className='inputbox' placeholder='Gázóra állásod' type="text" name="gaz" onChange={this.handleChange} defaultValue={this.props.lastdata.gaz} autoComplete='off'/>
                        </fieldset>
                        <br/>
                        <input id='submit' type="submit" value="Adatok módosítása" />
                    </form>
                   
                </div>
            </div>
        );
    }
}
export default Data;