import coin from "./Images/coin.png"

function Level({score}) {
    return(
        <div className="score-container">
            <img src={coin} alt="" />
            <br/>
            <h4 >{score}</h4>
        </div>
    );
}

export default Level;