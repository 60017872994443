import back_icon from "../Images/back.png";
import NameStyles from "./Namestyles";
import Selectname from "./Selectname";
import "./profile.css";
import Icon from "../Images/logo192.png";
import gear from "../Images/gear.png";
import { useState } from "react";

function Profile({ name, back, nametype }) {
    const [load, setLoad] = useState("profile");

    const loadnamestyle = () => {
        setLoad("name");
    };

    const loadbarstyle = () => {
        setLoad("bar");
    };

    const loadppstyle = () => {
        setLoad("pp")
    }

    let page;
    if (load === "profile" && nametype === 0) {
        page = (
            <div className="profile-container">
                <button className="back" onClick={back}>
                    <img src={back_icon}/>
                </button>
                <div className="profile">
                    <div className="profile-name">
                        <img src={Icon} className="icon" />
                        <button onClick={loadppstyle} className="pp-button">
                            <img src={gear} />
                        </button>
                        <h1>{name}</h1>
                        <button onClick={loadnamestyle}>
                            <img src={gear} />
                        </button>
                    </div>
                    <div className="row-style">
                        <div className="row-type">
                            {name}
                        </div>
                        <button onClick={loadbarstyle}>
                            <img src={gear} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }else if (load === "profile" && nametype === 1) {
        page = (
            <div className="profile-container">
                <button className="back" onClick={back}>
                    <img src={back_icon} alt="" />
                </button>
                <div className="profile">
                    <div className="profile-name">
                        <img src={Icon} className="icon" />
                        <button onClick={loadppstyle} className="pp-button">
                            <img src={gear} />
                        </button>
                        <h1 className="gradient">{name}</h1>
                        <button onClick={loadnamestyle}>
                            <img src={gear} />
                        </button>
                    </div>
                    <div className="row-style">
                        <div className="row-type">
                            {name}
                        </div>
                        <button onClick={loadbarstyle}>
                            <img src={gear} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }else if (load === "profile" && nametype === 2) {
        page = (
            <div className="profile-container">
                <button className="back" onClick={back}>
                    <img src={back_icon} alt="" />
                </button>
                <div className="profile">
                    <div className="profile-name">
                        <img src={Icon} className="icon" />
                        <button onClick={loadppstyle} className="pp-button">
                            <img src={gear} />
                        </button>
                        <svg>
                            <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                                {name}
                            </text>
                        </svg>
                        <button onClick={loadnamestyle}>
                            <img src={gear} />
                        </button>
                    </div>
                    <div className="row-style">
                        <div className="row-type">
                            {name}
                        </div>
                        <button onClick={loadbarstyle}>
                            <img src={gear} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }else if (load === "bar") {
        page = (
            <div className="profile-container">
                <button className="back" onClick={() => setLoad("profile")}>
                    <img src={back_icon} alt="" />
                </button>
                <div className="profile">
                    <div className="profile-name">
                        
                    </div>
                </div>
            </div>
        );
    } else if (load === "name") {
        page = (
            <div className="profile-container">
                <button className="back" onClick={() => setLoad("profile")}>
                    <img src={back_icon}/>
                </button>
                <div className="selector">
                    <form className="name-style-form">
                        <Selectname name={name}/>
                    </form>
                </div>
            </div>
        );
    }else if(load === "pp"){
        page = (
            <div className="profile-container">
                <button className="back" onClick={() => setLoad("profile")}>
                    <img src={back_icon}/>
                </button>
                <div className="profile">
                    <div className="profile-name">
                        
                    </div>
                </div>
            </div>
        );
    }

    return page;
}

export default Profile;
