import React, { useState, useEffect } from "react";
import axios from "axios";
import back_icon from "../Images/back.png";
import refresh_icon from "../Images/refresh.png";
import "./Tips.css";

function Tips({ back, tippek }) {
    const [randomTip, setRandomTip] = useState(null);
    const [toggle, setToggle] = useState("tipp");

    const fetchRandomTip = () => {
        axios.post("https://homecraftdb.crnl.hu/randomtip")
            .then(res => {
                if (res.data.error) {
                    console.error("Error during getting tips", res.data.error);
                } else {
                    console.log("data:", res);
                    setRandomTip(res.data.data[0].tipp);
                    console.log(res.data.data[0].tipp);
                }
            })
            .catch(err => {
                console.error("Error fetching random tip:", err);
            });
    };

    const handleRefreshClick = () => {
        fetchRandomTip();
    };

    // Fetch a random tip on component mount
    useEffect(() => {
        fetchRandomTip();
    }, []);

    const toggleValue = () => {
        setToggle(toggle === 'tipp' ? 'feladat' : 'tipp');
    };

    let content;
    if (toggle === 'tipp'){
        content = (
            <div id='main'>
                <div id="home">
                    <button className="back" onClick={back}><img src={back_icon} alt="" /></button>
                    <div className='toggle-switch-container-center'>
                        <div className='toggle-switch'>
                            <span className={`option ${toggle === 'tipp' && 'active'}`} onClick={() => setToggle('tipp')}>Tippek</span>
                    
                            <span className={`option ${toggle === 'feladat' && 'active'}`} onClick={() => setToggle('feladat')}>Feladatok</span>
                        </div>
                    </div>

                    <div className="tip-container">
                        {randomTip && (
                            <div className="tip">
                                {randomTip}
                            </div>
                        )}
                        <button className="refbutton" onClick={handleRefreshClick}><img src={refresh_icon}></img></button>
                    </div>
                </div>
            </div>
        );
    }else{
        content = (
            <div id='main'>
                <div id="home">
                    <button className="back" onClick={back}><img src={back_icon} alt="" /></button>
                    <div className='toggle-switch-container-center'>
                        <div className='toggle-switch-tip'>
                            <span className={`option ${toggle === 'tipp' && 'active'}`} onClick={() => setToggle('tipp')}>Tippek</span>
                    
                            <span className={`option ${toggle === 'feladat' && 'active'}`} onClick={() => setToggle('feladat')}>Feladatok</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return content
}

export default Tips;
